<template>
  <div class="product">
    <Banner></Banner>
    <div class="context">
      <h2 class="h2">产品特性</h2>
      <div class="item" v-for="(item, index) in productList" :key="index">
        <h3>{{ item.h3 }}</h3>
        <p>{{ item.text }}</p>
        <img :src="item.image" />
      </div>

      <h2 class="h2">产品功能</h2>

      <div class="fun_context">
        <div class="fun_item" v-for="(item, index) in funList" :key="index">
          <div class="img"><img :src="item.image" /></div>
          <div class="describe">
            <div class="fun_title">{{ item.name }}</div>
            <div class="fun_text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <FootMain> </FootMain>
  </div>
</template>

<script>
import FootMain from "@/components/m/foot_main/index.vue";
import Banner from "@/components/m/product/product.vue";
export default {
  data() {
    return {
      productList: [
        {
          h3: "多种认证方式",
          text:
            "手机短信认证，微信公众号认证，钉钉账号认证，账号密码认证（支持关联LDAP、AD域，API接口认证），员工授权认证，二维码识别认证，其他第三方数据源校验完成认证。",
          image: require("@/assets/img/m/home/why_swiper01.jpg"),
        },
        {
          h3: "兼容主流品牌",
          text:
            "兼容主流有线无线网络设备，如华为、H3C、锐捷、Cisco、Aruba、RUCKUS。多种品牌设备共存于一个管理系统，去繁存简、兼容并包。",
          image: require("@/assets/img/m/home/why_swiper02.jpg"),
        },
        {
          h3: "配置简单",
          text:
            "所有配置部署过程均采用向导式配置方式，可视化的认证页面定制， 无须专业的网络管理员也可轻松完成平台部署配置工作，并提供视频资料以及文档资料满足更复杂的开发的配置任务。",
          image: require("@/assets/img/m/home/why_swiper03.jpg"),
        },
        {
          h3: "旁挂接入",
          text:
            "旁挂接入，不影响现有网络拓扑结构。最小化成本实现认证系统接入。轻松实现有线无线统一准入控制，为企业上网安全保驾护航。",
          image: require("@/assets/img/m/home/why_swiper04.jpg"),
        },
      ],
      funList: [
        {
          name: "BOSS驾驶舱",
          text:
            "实时监测在线用户数量， 带宽，设备类型，认证方 式以及服务器当前状态",
          image: require("@/assets/img/pc/product/productfun01.png"),
        },
        {
          name: "控制器管理",
          text: "在此添加软件授权时允许 的控制器数量，超出则需 要额外购买授权",
          image: require("@/assets/img/pc/product/productfun02.png"),
        },
        {
          name: "策略配置",
          text: "个性化配置用户、群组 的上网带宽，时长以及 时间段",
          image: require("@/assets/img/pc/product/productfun03.png"),
        },
        {
          name: "认证方式",
          text:
            "针对用户场景，启用合适的 接入方式，目前系统默认支 持9种认证方式",
          image: require("@/assets/img/pc/product/productfun04.png"),
        },
        {
          name: "员工管理",
          text:
            "个性化设置用户上网策略， 支持账户关联指定设备，并 实现无感知接入",
          image: require("@/assets/img/pc/product/productfun05.png"),
        },
        {
          name: "多数据源",
          text: "支持AD，LDAP，OA， CRM，钉钉，邮件，PMS 等第三方数据源校验",
          image: require("@/assets/img/pc/product/productfun06.png"),
        },
        {
          name: "黑白名单",
          text:
            "通过黑白名单方式，以支持 无感知登录，并智能过滤拦 截特定用户。",
          image: require("@/assets/img/pc/product/productfun07.png"),
        },
        {
          name: "在线用户",
          text: "后台实时查看在线设备， 异常上网终端可以在后台 断开网络",
          image: require("@/assets/img/pc/product/productfun08.png"),
        },
      ],
    };
  },
  components: {
    FootMain,
    Banner,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/m/product/product.scss";
</style>
